<template>
  <div class="documents-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'DocumentsWrapper',
};
</script>
